import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Col, notification, Row } from 'antd'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CLOSE_ICON from '../../../../assets/images/close-success.png'
import ERROR from '../../../../assets/images/error.png'
import SUCCESS from '../../../../assets/images/success.png'
import Loading from '../../../../components/Common/Loading/Loading'
import clientWarehouseApi from '../../../../http/clientWarehouseApi'
import { Client } from '../../../../models'
import { CommonError } from '../../../../models/common/error'
import ClientMainSection, {
  ClientMainSectionStateInterface,
} from './ClientMainSection'
import Gestion, { ClientGestionStateInterface } from './Gestion'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { focusById } from '../../../../hook/usePressTab'
import './../Client.scss'

const ClientCreate = () => {
  const clientKey = useSelector((state: RootState) => state.selector).data
    .client
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mainSectionData, setMainSectionData] =
    useState<ClientMainSectionStateInterface>()

  const [gestionData, setGestionData] = useState<ClientGestionStateInterface>()
  const [errorMainSection, setErrorMainSection] = useState<CommonError[]>([])
  const [errorClientGestion, setErrorClientGestion] = useState<CommonError[]>(
    []
  )
  const [isValidMainSection, setIsValidMainSection] = useState<boolean>(false)
  const [isValidGestionSection, setIsValidGestionSection] =
    useState<boolean>(false)
  const isValidAll = isValidMainSection && isValidGestionSection

  useEffect(() => {
    let errorMainSectionClone = cloneDeep(errorMainSection)
    if (mainSectionData?.code) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) =>
          !(
            item.name === 'code' &&
            (item.errorMessage === 'Code déjà existant' ||
              item.errorMessage === ' ')
          )
      )
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) =>
          !(item.name === 'code' && item.errorMessage === 'Code déjà existant')
      )
    }
    if (mainSectionData?.nom) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'nom' && item.errorMessage !== ' '
      )
    }
    if (mainSectionData?.email) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'email' && item.errorMessage !== ' '
      )
    }
    setErrorMainSection(errorMainSectionClone)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSectionData])

  useEffect(() => {
    let errorClientGestionClone = cloneDeep(errorClientGestion)

    if (gestionData?.mois_dluo_input || !gestionData?.mois_dluo_activate) {
      errorClientGestionClone = errorClientGestionClone.filter(
        (item) =>
          !(item.name === 'mois_dluo_input' && item.errorMessage === ' ')
      )
    }
    setErrorClientGestion(errorClientGestionClone)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gestionData])

  const onCancelModal = () => {
    navigate(
      `/configurations/client?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const onChangeDataMainSection = (data: ClientMainSectionStateInterface) => {
    setMainSectionData(data)
  }

  const onChangeGestion = (data: ClientGestionStateInterface) => {
    setGestionData(data)
  }

  const onCheckValidMainSection = (isValid: boolean) => {
    setIsValidMainSection(isValid)
  }

  const onCheckValidGestionSection = (isValid: boolean) => {
    setIsValidGestionSection(isValid)
  }

  const onSubmit = () => {
    let errorMainSectionClone = cloneDeep(errorMainSection)
    let errorClientGestionClone = cloneDeep(errorClientGestion)
    if (!mainSectionData?.code) {
      errorMainSectionClone.push({
        name: 'code',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'code' && item.errorMessage !== ' '
      )
    }
    if (!mainSectionData?.nom) {
      errorMainSectionClone.push({
        name: 'nom',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'nom' && item.errorMessage !== ' '
      )
    }
    if (!mainSectionData?.email) {
      errorMainSectionClone.push({
        name: 'email',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'email' && item.errorMessage !== ' '
      )
    }
    setErrorMainSection(errorMainSectionClone)
    if (gestionData?.mois_dluo_activate && !gestionData.mois_dluo_input) {
      errorClientGestionClone.push({
        name: 'mois_dluo_input',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'mois_dluo_input' && item.errorMessage !== ' '
      )
    }
    setErrorClientGestion(errorClientGestionClone)
    let data: Client = {}
    data.nom = mainSectionData?.nom.trim()
    data.code = mainSectionData?.code.trim()
    data.email = mainSectionData?.email
    data.warehouse_client = mainSectionData?.warehouse_client
    data.client_company = mainSectionData?.client_company
    data.rupture = gestionData?.rupture
    data.fifo = gestionData?.fifo
    data.fefo = gestionData?.fefo
    data.mois_dluo_activate = gestionData?.mois_dluo_activate
    data.mois_dluo_input = gestionData?.mois_dluo_input
    data.variantes = gestionData?.variantes || false
    data.client_warehouse_company_org =
      mainSectionData?.client_warehouse_company_org
    if (!isValidAll) return
    if (data.mois_dluo_input && data.mois_dluo_input > 24) return
    if (isValidAll) {
      setIsLoading(true)
      clientWarehouseApi
        .createClient(data)
        .then((res) => {
          notification.open({
            className: 'noti noti-success',
            message: (
              <div className="flex items-center">
                <img
                  src={SUCCESS}
                  alt="success"
                  width={50}
                  className="mr-2.5"
                />
                Succès: Client enregistré
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
          setIsLoading(false)
          onCancelModal()
        })
        .catch((e) => {
          if (e.response?.data?.error?.includes('clients_code_key')) {
            const errorMainSectionClone = cloneDeep(errorMainSection)
            errorMainSectionClone.push({
              name: 'code',
              errorMessage: 'Code déjà existant',
            })
            setErrorMainSection(errorMainSectionClone)
          } else
            notification.open({
              className: 'noti noti-error',
              message: (
                <div className="flex items-center">
                  <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                  Une erreur s'est produite. Merci de réessayer ultérieurement
                </div>
              ),
              placement: 'topRight',
              closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
              duration: 3,
            })
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    // default focus
    focusById(clientKey['nom'])
  }, [])

  return (
    <div className="create-section">
      {isLoading && <Loading />}
      <Row className="bd-bot-page-title pb-3.5" style={{ height: '10%' }}>
        <Col className="flex items-center">
          <ArrowLeftOutlined
            className="text-primary text-2xl"
            onClick={onCancelModal}
          />
          <span className="modal-title-heading-1 ml-3 mr-5">Création : </span>
          <span className="text-primary text-2xl">Client</span>
        </Col>
      </Row>
      <div
        className="overflow-y-auto main-content "
        style={{ height: '100vh' }}
      >
        <div className="h-8"></div>

        <ClientMainSection
          onChangeData={onChangeDataMainSection}
          onCheckValid={onCheckValidMainSection}
          mainSectionError={errorMainSection}
        />
        <div className="h-4"></div>
        <Gestion
          onChangeData={onChangeGestion}
          clientGestionError={errorClientGestion}
          onCheckValid={onCheckValidGestionSection}
        />
      </div>
      <div
        className="fixed-bottom flex justify-end items-center"
        style={{ paddingRight: 0 }}
      >
        <Button
          id={clientKey['Sauvegarder']}
          data-previous-id={`${clientKey['variante']}, ${clientKey['mois-nbr']}, ${clientKey['mois']}`}
          data-next-id={clientKey['nom']}
          disabled={isLoading}
          className={`btn-submit-modal text-btn-submit-last-modal mr-3`}
          onClick={onSubmit}
        >
          Sauvegarder
        </Button>
      </div>
    </div>
  )
}

export default ClientCreate
